<template>
    <p-modal>
        <template #title>Файл юклаш</template>
        <div>{{ this.parentFolder }} да янги файл яратилади<br />{{ $l("platon.select_file", "Файлни танланг") }}</div>

        <input type="file" @change="onFileSelected" ref="file" multiple />

        <div class="mt-2" v-if="!existences">
            <button class="btn btn-primary" :disabled="inputVal == null" @click="upload">Юклаш</button>
        </div>
        <div class="exists" v-if="existences">
            <h3>{{ existencesList.length }}та файл саҳифада мавжуд:</h3>
            <ul>
                <li v-for="item in existencesList" :key="item">{{ item }}</li>
            </ul>
            <button class="btn btn-primary" @click="close">Тушунарли</button>
        </div>
    </p-modal>
</template>

<style scoped lang="scss">
@import "../../assets/styles/vars.scss";

.exists {
    margin: 10px 0;
}

.exists ul {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
}

.exists li {
    font-size: 14px;
    padding-left: 20px;
    line-height: 120%;
}

.exists h3 {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 10px;
    color: $red;
}
</style>

<script>
export default {
    name: "FileUploadDialog",

    props: {
        parentFolder: {},
        createFile: {}
    },

    data() {
        return {
            inputVal: null,
            existences: false,
            existencesList: []
        }
    },

    methods: {
        onFileSelected(event) {
            const files = event.target.files
            this.existences = false
            this.inputVal = files
        },

        async upload() {
            const result = await this.createFile(this.parentFolder, this.inputVal)

            if (result.length === 0) {
                this.$emit("close")
            } else {
                this.existencesList = result
                this.existences = true
            }
        },

        close() {
            this.$emit("close")
        }
    }
}
</script>
